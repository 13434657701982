<template>
  <sub-page
      :title="$t('Meetings')"
      v-model="$store.state.settings.pageData"
  >
    <ws-data-table
        :items="items"
        :headers="headers"
    >

    </ws-data-table>

  </sub-page>
</template>

<script>
export default {
  name: "Meetings",
  data() {
    return {
      items : [
        { name : 'Very important meeting' , date : 12312333  },
        { name : 'Mega new project' , date : 12312333  }
      ]
    }
  },
  computed : {
    headers() {
      return [
        { text : this.$t('Name') , value : 'name' },
        { text : this.$t('Date') , value : 'date' }
      ]
    }
  }
}
</script>

<style scoped>

</style>